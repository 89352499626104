<atom-title-book [pathMode]="pathMode" [path]="['/b', book.bookNid]" [queryParams]="queryParams" [replaceUrl]="replaceUrl" [titleBook]="book.title"
  [size]="infoBook.title.size"></atom-title-book>
<ng-container *ngIf="infoBook.author.isDisplay">
  <div *ngIf="authorBook;else normal">
    <atom-author [authors]="book.authors"></atom-author>
  </div>
  <ng-template #normal>
    <a [routerLink]="['/a', book.username]" [queryParams]="queryParams" class="text-xs font-bold sm: cursor-pointer outline-none truncate py-0.5">
      {{ book.name }}
    </a>
  </ng-template>
</ng-container>
<ng-container *ngIf="infoBook.lastChapter.isDisplay">
  <base-btn class="mb-0.5 mt-1 md:mt-0" [path]="book.price ? '/b' + book.bookNid : '/b' + book.bookNid + '/read?chapterNid=' + book.lastChapter.chapterNid"
    [chapterPosition]="book.lastChapter.position">
  </base-btn>
</ng-container>
<ng-container *ngIf="infoBook.price.isDisplay">
  <atom-price class="py-0.5 h-6" *ngIf="infoBook.price.isReader" [price]="book.price" [percent]="book.percent" [endDate]="book.endDate"></atom-price>
</ng-container>

<ng-container *ngIf="infoBook.description.isDisplay">
  <p class="leading-5 break-words text-left text-xs md: py-0.5  ">
    {{ book.description === null ? 'Hiện tại truyện chưa có phần giới thiệu' : book.description}}
  </p>
</ng-container>
<ng-container *ngIf="infoBook.genres.isDisplay">
  <div class="flex flex-wrap h-8 md:h-20 overflow-hidden text-left">
    <div class="font-bold pr-1 md:pr-2 text-xs md:  ">Thể loại:</div>
    <span class="text-xs md: flex flex-wrap" *ngFor="let genre of book.genres | keyvalue; let i = index; let lastIndex = count">
      <span>{{ $any(genre.value).name }}</span>
      <span class="relative mr-0.5" *ngIf="i !== lastIndex - 1">,</span>
    </span>
  </div>
</ng-container>