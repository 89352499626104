import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class AuthValidator {
  constructor() {}

  passwordMatchValidator(control: AbstractControl, minChar = 4): ValidationErrors | null {
    const passwordCtrl = control.get('password');
    const confirmpasswordCtrl = control.get('confirmpassword');
    const passwordValue = passwordCtrl?.value;
    const confirmpasswordValue = confirmpasswordCtrl?.value;
    const matchPassword = control && passwordValue === confirmpasswordValue && passwordValue > 0 && confirmpasswordValue > 0;
    const noData = control && passwordValue === confirmpasswordValue && passwordValue === 0 && confirmpasswordValue === 0;
    if (!control) {
      return null;
    } else if (matchPassword) {
      return null;
    } else if (noData) {
      return null;
    } else if (passwordValue > 0 && confirmpasswordValue > 0 && passwordValue !== confirmpasswordValue) {
      return { missmatch: true };
    } else {
      return null;
    }
  }

  passwordMatchValidatorOld(c: AbstractControl): ValidationErrors | null {
    const matchPassword = c && c.get('password')?.value === c.get('confirmpassword')?.value;
    if (matchPassword) {
      c.get('password')?.setErrors(null);
      c.get('confirmpassword')?.setErrors(null);
      return null;
    } else {
      c.get('password')?.setErrors({ missmatch: true });
      c.get('confirmpassword')?.setErrors({ missmatch: true });
      return { missmatch: true };
    }
  }
}
