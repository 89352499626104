<ng-container [ngTemplateOutlet]="style === 1 ? style1Template : style2Template" [ngTemplateOutletContext]="{tabs, activeTabId}"> </ng-container>

<ng-template #style2Template let-tabs="tabs" let-activeTabId="activeTabId">
  <div class="max-w-screen-xl mx-auto md:px-6 xl:px-10">
    <div class="flex items-center border-b border-gray-200">
      <ng-container *ngFor="let tabObj of tabs  | keyvalue: orderByPosition">
        <atom-rd-library-tab-item *ngIf="$any(tabObj.value) as tab" class="mb" (submitEvent)="selectTab(tab.tabId); submitEvent.emit(tab)" [tab]="tab"
          [activeTabId]="activeTabId"></atom-rd-library-tab-item>
      </ng-container>
    </div>
  </div>
  <div class="pt-5">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<ng-template #style1Template let-tabs="tabs" let-activeTabId="activeTabId">
  <div class="max-w-screen-xl mx-auto px-6 xl:px-10">
    <div class="grid grid-cols-10 gap-8 xl:gap-14 ">
      <div class="col-span-3 lg:col-span-2">
        <div class="shadow-md">
          <div class="py-4 px-4">
            <ng-container *ngFor="let tabObj of tabs | keyvalue">
              <div *ngIf="$any(tabObj.value) as tab" (click)="selectTab(tab.tabId); submitEvent.emit(tab)" class="p-3 flex items-center cursor-pointer">
                <div [ngClass]="tab.tabId === activeTabId ? 'border-accent text-accent' : 'border-gray-200'"
                  class="border p-0.5 rounded-sm w-6 mr-2 h-6 flex items-center justify-center">
                  <ng-container *ngIf="tab.icon; else imageTemplate">
                    <fa-icon class="md:text-2xl text-xl {{tab.tabId === activeTabId ? 'text-accent' : 'text-slate-800'}}" [icon]="tab.icon"></fa-icon>
                  </ng-container>
                  <ng-template #imageTemplate>
                    <img [src]="tab.image" class="w-full" alt="Icon" />
                  </ng-template>
                </div>
                <div [ngClass]="tab.tabId === activeTabId ? 'text-accent' : ''" class="flex items-center ">{{ tab.title }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-span-7 lg:col-span-8">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>