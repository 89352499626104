import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TitleAtom } from '../../atoms/title/title.atom.js';
import { RdLibraryTabItemAtom } from '../../atoms/rd-library-tab-item/rd-library-tab-item.atom.js';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPosition } from '@awread/kernel/tools';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'molec-rd-tab-mobile',
  imports: [CommonModule, TitleAtom, RdLibraryTabItemAtom],
  templateUrl: './rd-tab-mobile.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdTabMobileMolec implements OnInit {
  @Input() isTabsHome = false;
  @Input() data!: any;
  @Input() expandTab: any = {};
  @Input() tabs: any = {};
  @Input() activeTabId!: string;
  @Output() action = new EventEmitter();
  parentRouteUrl = '';
  orderByPosition = OrderByPosition;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.isTabsHome) {
      // no active tab
    } else {
      this.activeTabId = Object.keys(this.tabs)[0];
      // console.log('init', this.tabs, this.activeTabId, this.activatedRoute, this.router);
      this.activatedRoute.url.pipe(untilDestroyed(this)).subscribe((url) => {
        const activeTabId = this.router.url.split('?')[0].split(';')[0].split(':')[0].split('/').at(-1);
        this.parentRouteUrl = this.router.url.split('?')[0].split(';')[0].split(':')[0].split('/').at(-2) ?? '';
        // console.log('activeTabId', activeTabId, this.router.url);
        if (activeTabId) {
          // console.log('we have activeTabId now is', activeTabId);
          this.activeTabId = activeTabId;
        }
      });
    }
  }

  selectTab(tabId: any, tabValue: any) {
    if (tabId === 'filter') {
      this.action.emit({ group: 'open-dialog-filter-book' });
      return;
    }
    if (tabId === 'quote') {
      this.action.emit({ group: 'open-dialog-create-quote' });
      return;
    }
    if (tabId === 'genres') {
      this.action.emit({ group: 'expand-genres' });
      return;
    }
    if (tabId === 'tags') {
      this.action.emit({ group: 'expand-tags' });
      return;
    }
    if (tabValue.newTabHref) {
      console.log('window opening', tabValue.newTabHref);
      window.open(tabValue.newTabHref);
    } else {
      if (this.isTabsHome) {
        console.log(`routing to /${tabId}`);
        this.router.navigateByUrl(`/${tabId}`, { replaceUrl: false });
      } else {
        this.router.navigateByUrl(`/${this.parentRouteUrl}/${tabId}`, { replaceUrl: true });
      }
      this.activeTabId = tabId;
    }
  }
}
