<div class="flex gap-4 justify-center">
  <ng-container *ngFor="let tabObj of tabs | keyvalue: orderByPosition">
    <atom-rd-library-tab-item class="py-2" (submitEvent)="selectTab(tabObj.key, tabObj.value);" [tab]="tabObj.value"
      [activeTabId]="activeTabId"></atom-rd-library-tab-item>
  </ng-container>
</div>

<div class="flex justify-center mx-4">
  <ng-container *ngIf="expandTab.isGenres">
    <div class="bg-gradient-to-r from-[#5d445d] via-[#805e80] to-[#b881b8] rounded-md pt-4 pb-2 md:pb-0 px-4 md:px-6 max-w-[45rem] w-fit mb-6 -mt-4 mx-auto">
      <div class="flex flex-wrap">
        <div (click)="action.emit({ group: 'filter-book', type: 'genre', genreId: genre.genreId })" *ngFor="let genre of data.trendGenres"
          class="col-span-2 cursor-pointer border rounded-md bg-white text-primary py-1 px-4 text-center font-bold text-xs md:  mr-2 md:mr-4 mb-2">
          {{genre.name}}
        </div>
      </div>
      <button (click)="action.emit({ group: 'see-more', type: 'genre' })" class="text-white italic  cursor-pointer text-right w-full">Xem
        thêm...</button>
    </div>
  </ng-container>

  <ng-container *ngIf="expandTab.isTags">
    <div class="bg-gradient-to-r from-[#5d445d] via-[#805e80] to-[#b881b8] rounded-md pt-4 pb-2 md:pb-0 px-4 md:px-6 max-w-[45rem] w-fit mb-6 -mt-4 mx-auto">
      <div class="flex flex-wrap ">
        <div (click)="action.emit({ group: 'filter-book', type: 'tag', tagId: tag.tagId })" *ngFor="let tag of data.trendTags"
          class="col-span-2 cursor-pointer border rounded-md bg-white text-primary py-1 px-4 text-center font-bold text-xs md: mr-2 md:mr-4 mb-2">
          {{tag.name}}</div>
      </div>
      <button (click)="action.emit({ group: 'see-more', type: 'tag' })" class="text-white italic  cursor-pointer text-right w-full">Xem thêm...</button>
    </div>
  </ng-container>
</div>