<div class="popup-cover-content h-full flex items-center">
  <atom-popup-cover-image-choose *ngIf="status === 'pending'" class="w-full py-5" [controlName]="controlName" (eventChooseImage)="chooseImage($event)">
  </atom-popup-cover-image-choose>

  <!-- <pre> status {{ status }} </pre> -->

  <atom-process-bar *ngIf="status === 'loading'" class="w-full px-11" [percentLoading]="percentLoading"></atom-process-bar>
  <atom-popup-cover-image-align
    [roundCropper]="roundCropper"
    [aspectRatio]="aspectRatio"
    [imageChangedEvent]="imageChangedEvent"
    *ngIf="status === 'reposition'"
    class="w-full h-full"
    (submitEvent)="submitEvent.emit($event)"
  >
  </atom-popup-cover-image-align>

  <!-- <pre> {{ imageChangedEvent }} </pre> -->
</div>
