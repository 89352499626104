import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleAtom } from '../../atoms/title/title.atom.js';
import { InputFormProfileAtom } from '../../atoms/input-form-profile/input-form-profile.atom.js';
import { BtnRectangleAtom } from '../../atoms/btn-rectangle/btn-rectangle.atom.js';
import { SocialLoginBtnAtom } from '../../atoms/index.js';

@Component({
  standalone: true,
  selector: 'molec-profile-edit-form',
  imports: [CommonModule, ReactiveFormsModule, TitleAtom, InputFormProfileAtom, BtnRectangleAtom, SocialLoginBtnAtom],
  templateUrl: './profile-edit-form.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
      .invalid-feedback {
        color: red;
        margin-top: 0.25rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEditFormMolec {
  fb = inject(FormBuilder);

  @Input() isReader = false;
  @Input() requireForm: FormGroup = this.fb.group({
    username: ['', [Validators.required]],
    name: ['', [Validators.required]],
    email: [null, [Validators.email]],
    phone: [null],
    avatar: [false],
  });
  class = ' cursor-pointer rounded-full text-white border-primary font-semibold py-1 md:py-2 px-6';
  @Output() submitEvent = new EventEmitter();
  @Input() submitted = false;
  @Input() items = [
    {
      title: 'Tên hiển thị (có dấu)',
      formControlName: 'name',
    },
    {
      title: 'Username (không dấu)',
      formControlName: 'username',
    },
    {
      title: 'Email',
      formControlName: 'email',
    },
    {
      title: 'Số điện thoại',
      formControlName: 'phone',
    },
  ];



  regexusername(str: string) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/\s/g, '');
    //str= str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,"-");
    /* tìm và thay thế các kí tự đặc biệt trong chuỗi sang kí tự - */
    //str= str.replace(/-+-/g,"-"); //thay thế 2- thành 1-
    // str = str.replace(/^\-+|\-+$/g, '');
    this.requireForm.get('username')?.setValue(str);
  }
}
