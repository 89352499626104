import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RatingPipe } from '@awread/core/books';
import { AuthorAtom } from '../../atoms/author/author.atom.js';
import { BaseBtnAtom } from '../../atoms/base-btn/base-btn.atom.js';
import { PriceAtom } from '../../atoms/price/price.atom.js';
import { TitleBookAtom } from '../../atoms/title-book/title-book.atom.js';

@Component({
  standalone: true,
  selector: 'molec-info-book',
  imports: [CommonModule, RouterModule, BaseBtnAtom, PriceAtom, AuthorAtom, RatingPipe, TitleBookAtom],
  templateUrl: './info-book.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      @media only screen and (max-width: 1025px) {
        :host p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBookMolec {
  lastGenre = 0;
  @Input() authorBook = false;
  @Input() pathMode: 'normal' | 'no-path' | 'absolute' = 'normal';
  @Input() infoBook: any = {
    title: {
      pathMode: 'normal',
      size: 'm',
    },
    rating: {
      isDisplay: false,
    },
    actions: {
      isDisplay: false,
    },
    author: {
      isDisplay: true,
      color: 'text-primary',
    },
    price: {
      isDisplay: true,
      isReader: true,
    },
    description: {
      isDisplay: false,
    },
    genres: {
      isDisplay: false,
    },
    lastChapter: {
      isDisplay: false,
    },
  };
  @Input() displayUI: any;

  @Input() book: any;

  @Input() queryParams = {};
  @Input() replaceUrl = false;

  constructor(private cd: ChangeDetectorRef) { }



  // orderByPosition(a: any, b: any) {
  //   return b.value.position > a.value.position ? -1 : a.value.position > b.value.position ? 1 : 0;
  // }
}
