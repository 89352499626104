<div class="grid grid-cols-12 gap-4 md:gap-2 lg:gap-4 pt-4 md:pt-2 py-2">
  <div class="col-span-4 md:col-span-6 xl:col-span-5">
    <atom-img-m [book]="book"></atom-img-m>
  </div>
  <div class="col-span-8 md:col-span-6 xl:col-span-7 pr-4">
    <atom-title-book [path]="['/b', book.bookNid]" [titleBook]="book.title" [size]="'m'"></atom-title-book>
    <atom-price *ngIf="book?.booksPrices?.nodes[0] as bookPrice" [price]="bookPrice.price ? bookPrice.price.price : 0"
      [percent]="bookPrice.discount ? bookPrice.discount.percent : 0" [endDate]="bookPrice.discount ? bookPrice.discount.endDate : null"
      class="pt-0.5 pb-1"></atom-price>
    <div *ngFor="let chapter of book?.chapters?.nodes" class="flex">
      <base-btn [path]="'/b' + book.bookNid + '/read?chapterNid=' + chapter.chapterNid" [chapterPosition]="chapter.position"> </base-btn>
      <div *ngIf="type === 'bookmark'">
        <div class="hidden xl:block">
          <rd-list-publication-date class="mt-0.5 ml-4" [publicationDate]="chapter.updatedAt"></rd-list-publication-date>
        </div>
      </div>
    </div>
    <div *ngIf="type === 'history'">
      <div class="hidden xl:block">
        <rd-list-publication-date class="mt-0.5" [publicationDate]="latestVisit"></rd-list-publication-date>
      </div>
    </div>
  </div>
</div>