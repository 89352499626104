import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { faEye, faComments, faShoppingCart, faHeart, faBookBookmark, faShareNodes, faStar, faPlayCircle, faReceipt, faChevronLeft, faBurger, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { IconTextAtom } from '../../atoms/icon-text/icon-text.atom.js';
import { RatingPipe } from '@awread/core/books';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

interface DisplayStatus {
  heart?: boolean;
  view?: boolean;
  comment?: boolean;
  bookmark?: boolean;
  review?: boolean;
  sale?: boolean;
  read?: boolean;
  back?: boolean;
  chapter?: boolean;
  donate?: boolean;
  share?: boolean;
  buy?: boolean;
}

@Component({
  standalone: true,
  selector: 'molec-detail-book-info-actions',
  imports: [CommonModule, IconTextAtom, RatingPipe, FontAwesomeModule],
  templateUrl: './detail-book-info-actions.molec.html',
  styles: [
    `
      :host {
        display: block;
        width: fit-content;
      }
      .shadow-action {
        box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailBookInfoActionsMolec {
  @Output() action = new EventEmitter();
  @Input() mode: 'detail' | 'reading' | 'writer' = 'reading';
  icons = {
    faEye, faComments, faHeart, faShoppingCart, faBookBookmark, faShareNodes, faStar, faPlayCircle, faReceipt, faBurger, faChevronLeft
  };
  @Input() book!: any;
  @Input() display: DisplayStatus = {
    donate: true,
    heart: true,
    read: true,
    review: true,
    sale: true,
    view: true,
    comment: true,
    bookmark: true,
    chapter: true,
    back: false,
  };
  @Input() loading!: string | undefined;
  @Input() direction: 'horizontal' | 'vertical' = 'vertical';
  @Input() iconSize: number = 2


}
