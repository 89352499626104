import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { TextLinkAtom } from '../../atoms/text-link/text-link.atom.js';

@Component({
  standalone: true,
  selector: 'molec-wrt-remember-forgot',
  imports: [TextLinkAtom],
  templateUrl: './wrt-remember-forgot.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrtRememberForgotMolec implements OnInit {
  @Input() link!: any | null;
  @Input() btnClicked = new EventEmitter();

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {}

  closeAllDidalog() {
    this.dialogService.closeAll();
  }
}
