import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseBtnAtom } from '../../atoms/base-btn/base-btn.atom.js';
import { ImgMAtom } from '../../atoms/img-m/img-m.atom.js';
import { PriceAtom } from '../../atoms/price/price.atom.js';
import { RdListPublicationDateAtom } from '../../atoms/rd-list-publication-date/rd-list-publication-date.atom.js';
import { TitleBookAtom } from '../../atoms/title-book/title-book.atom.js';

@Component({
  standalone: true,
  selector: 'molec-book-list-item-simple',
  imports: [CommonModule, ImgMAtom, TitleBookAtom, PriceAtom, BaseBtnAtom, RdListPublicationDateAtom],
  templateUrl: './book-list-item-simple.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookListItemSimpleMolec implements OnInit {
  @Input() book: any = {
    title: '',
    bookNid: '',
    price: '',
    percent: '',
    endDate: '',
    chaptersByBookId: {
      nodes: [],
    },
  };
  @Input() user: any;
  @Input() type = 'bookmark';
  @Input() latestVisit = '';
  constructor() {}

  ngOnInit(): void {}
}
