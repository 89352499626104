import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faLock, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthValidator } from '@awread/global/packages';
import { CommonModule } from '@angular/common';
import { InputFormAtom } from '../../atoms/input-form/input-form.atom.js';

@Component({
  standalone: true,
  selector: 'molec-register-form',
  imports: [CommonModule, ReactiveFormsModule, InputFormAtom],
  templateUrl: './register-form.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormMolec implements OnInit {
  @Input() type = 'password';
  icons = { faLock, faEnvelope, faUser, faPhone };
  @Input() form: FormGroup = this.fb.group(
    {
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(4)]],
      name: ['', [Validators.required]],
      email: ['', []],
      phone: ['', []],
    },
    { validator: this.authValidator.passwordMatchValidator }
  );

  @Output() blurEvent = new EventEmitter();
  constructor(private fb: FormBuilder, private authValidator: AuthValidator) {}

  ngOnInit(): void {}

  regexusername(str: string) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/\s/g, '');
    //str= str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,"-");
    /* tìm và thay thế các kí tự đặc biệt trong chuỗi sang kí tự - */
    //str= str.replace(/-+-/g,"-"); //thay thế 2- thành 1-
    // str = str.replace(/^\-+|\-+$/g, '');
    this.form.get('username')?.setValue(str);
  }
}
