import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PopupCoverImageAlignAtom } from '../../atoms/popup-cover-image-align/popup-cover-image-align.atom.js';
import { PopupCoverImageChooseAtom } from '../../atoms/popup-cover-image-choose/popup-cover-image-choose.atom.js';
import { ProcessBarAtom } from '../../atoms/process-bar/process-bar.atom.js';

@Component({
  standalone: true,
  selector: 'molec-popup-cover-image',
  imports: [CommonModule, PopupCoverImageChooseAtom, ProcessBarAtom, PopupCoverImageAlignAtom],
  templateUrl: './popup-cover-image.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCoverImageMolec implements OnInit {
  @Input() title = 'Chọn ảnh đại diện';
  @Input() status = 'pending';
  @Input() percentLoading = '50%';
  @Input() imageChangedEvent = '';
  @Input() aspectRatio = 1;
  @Input() roundCropper: any;
  @Input() controlName = new FormControl('');
  @Output() eventChooseImage = new EventEmitter();
  @Output() submitEvent = new EventEmitter();
  icon = faTimes;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  chooseImage(event: any) {
    this.imageChangedEvent = event;
    this.eventChooseImage.emit(event);
    this.cd.detectChanges();
  }
}
