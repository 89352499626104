<div [formGroup]="form">
  <input-form
    required
    [inputControl]="$any(form.get('name'))"
    (blurEvent)="blurEvent.emit({type: 'input', event: $event, field: 'name'})"
    [placeholder]="'Tên có dấu (bắt buộc): Cẩm Thương'"
    [faIcon]="icons.faUser"
  >
  </input-form>
  <input-form
    required
    [inputControl]="$any(form.get('username'))"
    (blurEvent)="blurEvent.emit({type: 'input', event: $event, field: 'username'})"
    [placeholder]="'Username (bắt buộc): camthuong'"
    [faIcon]="icons.faUser"
    (keyup)="regexusername($any($event.target).value)"
  >
  </input-form>
  <input-form required [type]="type" [inputControl]="$any(form.get('password'))" [placeholder]="'Mật khẩu (bắt buộc)'" [faIcon]="icons.faLock"> </input-form>
  <input-form required [type]="type" [inputControl]="$any(form.get('confirmpassword'))" [placeholder]="'Xác nhận mật khẩu (bắt buộc)'" [faIcon]="icons.faLock">
  </input-form>
  <input-form
    [inputControl]="$any(form.get('email'))"
    (blurEvent)="blurEvent.emit({type: 'input', event: $event, field: 'email'})"
    [placeholder]="'Email đăng nhập, lấy mật khẩu (bắt buộc)'"
    [faIcon]="icons.faEnvelope"
  >
  </input-form>
  <input-form
    [inputControl]="$any(form.get('phone'))"
    (blurEvent)="blurEvent.emit({type: 'input', event: $event, field: 'phone'})"
    [placeholder]="'Số điện thoại để đăng nhập'"
    [faIcon]="icons.faPhone"
  >
  </input-form>
</div>
