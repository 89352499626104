<form [formGroup]="form">
  <input-form
    (keyup.enter)="pwd.focus()"
    [inputControl]="$any(form.get('loginname'))"
    [placeholder]="'Nhập tài khoản / sdt / email'"
    [faIcon]="icons.faEnvelope"
  >
  </input-form>
  <input-form
    #pwd
    (keyup.enter)="auth.emit('email')"
    [type]="type"
    [inputControl]="$any(form.get('password'))"
    [placeholder]="'Nhập mật khẩu'"
    [faIcon]="icons.faLock"
  >
  </input-form>
</form>

<!-- <pre> {{ form.value | json }} </pre> -->
