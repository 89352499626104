import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RdLibraryTabItemAtom } from '../../atoms/index.js';
import { OrderByPosition } from '@awread/kernel/tools';
@UntilDestroy()
@Component({
  selector: 'molec-tab-left-desktop',
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule, RdLibraryTabItemAtom],
  templateUrl: './tab-left-desktop.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabLeftDesktopMolec implements OnInit {
  @Input() style = 1;
  @Input() tabs: any = {};
  @Input() activeTabId!: string;
  @Output() submitEvent = new EventEmitter();
  parentRouteUrl = '';
  orderByPosition = OrderByPosition;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeTabId = Object.keys(this.tabs)[0];
    // console.log('init', this.tabs, this.activeTabId, this.activatedRoute, this.router);
    this.activatedRoute.url.pipe(untilDestroyed(this)).subscribe((url) => {
      const activeTabId = this.router.url.split('?')[0].split(';')[0].split(':')[0].split('/').at(-1);
      this.parentRouteUrl = this.router.url.split('?')[0].split(';')[0].split(':')[0].split('/').at(-2) ?? '';
      // console.log(`guest route: ${this.parentRouteUrl}/${activeTabId}`);
      if (activeTabId) {
        // console.log('we have activeTabId now is', activeTabId);
        this.activeTabId = activeTabId;
      }
    });
  }

  selectTab(tabId: any) {
    this.router.navigateByUrl(`/${this.parentRouteUrl}/${tabId}`, { replaceUrl: true });
    this.activeTabId = tabId;
  }
}
