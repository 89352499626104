<section class="flex flex-col " [formGroup]="requireForm">
  <atom-title [class]="'font-medium  text-gray-500'" [title]="'Chỉnh sửa tài khoản'" [isUppercase]="false"></atom-title>

  <div class="my-4">
    <input-form-profile [profileForm]="requireForm" [item]="items[0]"></input-form-profile>

    <div class="flex">
      <div class="w-2/5"></div>
      <div *ngIf="requireForm.controls['name'].errors && requireForm.controls['name'].touched" class="invalid-feedback ml-6 w-3/5">
        <div *ngIf="requireForm.controls['name'].errors['required']">Tên hiển thị không được để trống</div>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <input-form-profile (blurEvent)="submitEvent.emit({event: $event, type: 'blur', field: 'username'})" [profileForm]="requireForm" [item]="items[1]"
      (keyup)="regexusername($any($event.target).value)">
    </input-form-profile>

    <div class="flex">
      <div class="w-2/5"></div>
      <div *ngIf="requireForm.controls['username'].errors && requireForm.controls['username'].touched" class="invalid-feedback ml-6 w-3/5">
        <div *ngIf="requireForm.controls['username'].errors['required']">Username không được để trống</div>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <input-form-profile (blurEvent)="submitEvent.emit({event: $event, type: 'blur', field: 'email'})" [profileForm]="requireForm" [item]="items[2]">
    </input-form-profile>

    <!-- <div class="flex">
      <div class="w-2/5"></div>
      <div *ngIf="requireForm.controls.email.errors && requireForm.controls.email.touched" class="invalid-feedback ml-6 w-3/5">
        <div *ngIf="requireForm.controls.email.errors.['required']">
          Email không được để trống
        </div>
      </div>
    </div> -->

    <!-- <div class="flex">
      <div class="w-2/5"></div>
      <div *ngIf="requireForm.controls.email.errors && requireForm.controls.email.touched" class="invalid-feedback ml-6 w-3/5">
        <div *ngIf="requireForm.controls.email.errors.email">
          Email không hợp lệ
        </div>
      </div>
    </div> -->
  </div>

  <div class="mb-4">
    <input-form-profile (blurEvent)="submitEvent.emit({event: $event, type: 'blur', field: 'phone'})" [profileForm]="requireForm" [item]="items[3]">
    </input-form-profile>
  </div>

  <div class="my-4 flex items-center">
    <div class="w-1/3 hidden md:block"></div>
    <div class="w-full flex justify-center md:justify-start md:w-2/3 md:pl-10">
      <atom-btn-rectangle *ngIf="isReader" class="mr-1" [class]="class" [text]="'Hủy'"
        (btnClicked)="submitEvent.emit({type: 'cancel-edit'})"></atom-btn-rectangle>
      <atom-btn-rectangle class="ml-1" [class]="class" [text]="'Lưu'" (btnClicked)="submitEvent.emit({type: 'save-user'})"></atom-btn-rectangle>
      <atom-btn-rectangle class="ml-1" [class]="class + ' ' + 'btn-accent'" [text]="'Hủy'"
        (btnClicked)="submitEvent.emit({type: 'cancel-edit'})"></atom-btn-rectangle>
    </div>
  </div>

  <div>
    <social-login-btn name="facebook" (socialBtnClicked)="submitEvent.emit({type: 'facebook'})"></social-login-btn>
    <social-login-btn name="google" (socialBtnClicked)="submitEvent.emit({type: 'google'})"></social-login-btn>
    <div class=" ">Kết nối với Facebook hay Google để đăng nhập nhanh hơn</div>
  </div>

  <div class="my-4 flex justify-center">
    <atom-btn-rectangle [class]="class" [text]="'Đổi mật khẩu'" (btnClicked)="submitEvent.emit({type: 'change-password'})"></atom-btn-rectangle>
  </div>
</section>