<section class="flex flex-col   ">
  <atom-title [class]="'font-medium text-xl text-accent '" [title]="'Thông tin cá nhân'" [isUppercase]="false"></atom-title>
  <div [formGroup]="optionalForm" class="pt-4">
    <div *ngFor="let item of inputFormItems | slice:0:3" class="mb-4">
      <input-form-profile [profileForm]="optionalForm" [item]="item"></input-form-profile>
    </div>

    <div class="mb-4 flex items-center justify-between">
      <label class="text-gray-400 w-2/5">Tiểu sử</label>
      <textarea formControlName="bio" placeholder="Tiểu sử"
        class="border rounded-xl border-gray-200 text-gray-700 focus:outline-none px-2 md:px-4 py-1 md:py-2 h-20 float-right w-3/5 focus:ring-1 focus:ring-gray-300 focus:border-gray-300"></textarea>
    </div>

    <div *ngFor="let item of inputFormItems | slice:3" class="mb-4">
      <input-form-profile [profileForm]="optionalForm" [item]="item"></input-form-profile>
    </div>
  </div>
  <div [formGroup]="experienceForm">
    <atom-select-profile [profileForm]="experienceForm" [item]="selectItems"></atom-select-profile>
  </div>
  <div class="mt-4 flex items-center ">
    <div class="w-1/3 hidden md:block"></div>
    <div class="w-full flex justify-center md:justify-start md:w-2/3 md:pl-10">
      <atom-btn-rectangle *ngIf="isReader" class="mr-1" [class]="class" [text]="'Hủy'"
        (btnClicked)="submitEvent.emit({type: 'cancel-edit'})"></atom-btn-rectangle>
      <atom-btn-rectangle class="ml-1" [class]="class" [text]="'Lưu'" (btnClicked)="submitEvent.emit({type: 'save-personal'})"></atom-btn-rectangle>
    </div>
  </div>
</section>