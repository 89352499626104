import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AuthDescriptionAtom } from '../../atoms/auth-description/auth-description.atom.js';
import { AuthTitleAtom } from '../../atoms/auth-title/auth-title.atom.js';

@Component({
  standalone: true,
  selector: 'molec-auth-header',
  imports: [AuthTitleAtom, AuthDescriptionAtom],
  templateUrl: './auth-header.molec.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthHeaderMolec implements OnInit {
  @Input() title = '';
  @Input() description = '';
  constructor() {}

  ngOnInit(): void {}
}
