<div class=" flex items-center justify-center" [ngClass]="{
   'flex-col py-2 px-2': direction === 'vertical',
   ' bg-black/30': mode === 'detail', 
   'bg-black/25 backdrop-blur-xl': mode === 'reading',
   'text-base-content': mode === 'writer',
   'text-white': mode === 'detail' || mode === 'reading'
}">
  <!-- <atom-icon-text [ngClass]="{'pr-2': direction === 'horizontal' }" [isSize]="iconSize" *ngIf="display.heart" [direction]="direction" [icon]="icons.faHeart"
    [title]="book.totalSale ?? 0"></atom-icon-text> -->


  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }" [isSize]="iconSize" *ngIf="display.view" [direction]="direction"
    [icon]="icons.faEye" [title]="book.totalView ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }" [isSize]="iconSize" *ngIf="display.sale" [direction]="direction"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-buy', book: book } })" [icon]="icons.faShoppingCart"
    [title]="book.totalSale ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }" [loading]="loading === 'donate'"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-donate', book: book } })" [isSize]="iconSize" *ngIf="display.donate"
    [direction]="direction" [icon]="icons.faBurger" [title]="book.totalDonate ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }"
    (click)="action.emit({ group: 'book-action', event: { type: 'share-book', bookNid: book.bookNid } })" [isSize]="iconSize" *ngIf="display.share"
    [direction]="direction" [icon]="icons.faShareNodes" [title]="book.totalShare ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }" [loading]="loading === 'bookmark'"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-bookmark', book: book } })" [isSize]="iconSize" [direction]="direction"
    [icon]="icons.faBookBookmark" [title]="book.totalBookmark ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-review', book: book } })" [isSize]="iconSize" *ngIf="display.review"
    [direction]="direction" [icon]="icons.faStar" [title]="book.totalReview ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-comment', book: book } })" [isSize]="iconSize" *ngIf="display.comment"
    [direction]="direction" [icon]="icons.faComments" [title]="book.totalComment ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }"
    (click)="action.emit({ group: 'book-action', event: { type: 'dialog-chapter', book: book } })" [isSize]="iconSize" *ngIf="display.chapter"
    [direction]="direction" [icon]="icons.faReceipt" [title]="book.totalChapter ?? 0"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-0.5 md:pr-2': direction === 'horizontal' }"
    (click)="action.emit({ group: 'book-action', event: { type: 'back-to-book', book: book } })" [isSize]="iconSize" *ngIf="display.back"
    [direction]="direction" [icon]="icons.faChevronLeft" [title]="'Rời'"></atom-icon-text>

  <atom-icon-text [ngClass]="{'pr-2': direction === 'horizontal' }" [isSize]="iconSize" *ngIf="display.read" [direction]="direction" [icon]="icons.faPlayCircle"
    [title]="''" (click)="action.emit({group: 'book-action', event: { type: 'continue-reading' }})"></atom-icon-text>
</div>